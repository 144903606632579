<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Complete the following table, by converting the given temperatures between Celsius and
        Fahrenheit units, as required:
      </p>

      <v-simple-table style="max-width: 350px">
        <thead>
          <tr>
            <td style="font-size: 14px">Part</td>
            <td style="text-align: center; font-size: 14px">Temp (<sup>o</sup>F)</td>
            <td style="text-align: center; font-size: 14px">Temp (<sup>o</sup>C)</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>a)</td>
            <td style="text-align: center"><number-value :value="tempF1" /></td>
            <td>
              <calculation-input
                v-model="inputs.tempC1"
                class="centered-input pa-2"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>b)</td>
            <td style="text-align: center"><number-value :value="tempF2" /></td>
            <td>
              <calculation-input
                v-model="inputs.tempC2"
                class="centered-input pa-2"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>c)</td>
            <td style="text-align: center"><number-value :value="tempF3" /></td>
            <td>
              <calculation-input
                v-model="inputs.tempC3"
                class="centered-input pa-2"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>d)</td>
            <td>
              <calculation-input
                v-model="inputs.tempF4"
                class="centered-input pa-2"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td style="text-align: center"><number-value :value="tempC4" /></td>
          </tr>
          <tr>
            <td>e)</td>
            <td>
              <calculation-input
                v-model="inputs.tempF5"
                class="centered-input pa-2"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td style="text-align: center"><number-value :value="tempC5" /></td>
          </tr>
          <tr>
            <td>f)</td>
            <td>
              <calculation-input
                v-model="inputs.tempF6"
                class="centered-input pa-2"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td style="text-align: center"><number-value :value="tempC6" /></td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question374',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        tempC1: null,
        tempC2: null,
        tempC3: null,
        tempF4: null,
        tempF5: null,
        tempF6: null,
      },
    };
  },
  computed: {
    tempF1() {
      return this.taskState.getValueBySymbol('tempF1').content;
    },
    tempF2() {
      return this.taskState.getValueBySymbol('tempF2').content;
    },
    tempF3() {
      return this.taskState.getValueBySymbol('tempF3').content;
    },
    tempC4() {
      return this.taskState.getValueBySymbol('tempC4').content;
    },
    tempC5() {
      return this.taskState.getValueBySymbol('tempC5').content;
    },
    tempC6() {
      return this.taskState.getValueBySymbol('tempC6').content;
    },
  },
};
</script>
